<template>
  <div class="CustomAnalysisList">
    <div style="margin-bottom: 20px">

      <v-select2 label="所属项目"
                 v-if="userType != 101"
                 :width="width"
                 v-bind="communityParams"
                 v-model="communityId"
                 @onChange="onCommunityChange"></v-select2>
      <!-- <v-select2 label="所属公司"
                 :width="width"
                 v-if="userType == 102"
                 v-bind="regionParams"
                 v-model="regionId"
                 @onChange="onRegionChange"></v-select2> -->
    </div>
    <iframe ref="iframe"
            v-if="iframeVisible"
            name="chart"
            class="iframe-container"
            :src="url"
            frameborder="0"></iframe>
  </div>
</template>

<script>
import { fetchChartURL } from './api'
import { communityParams, regionParams } from 'common/select2Params'
let url
export default {
  name: 'CustomAnalysisList',
  data () {
    return {
      communityParams,
      regionParams,
      width: 250,
      communityId: undefined,
      url: '',
      regionId: undefined,
      iframeVisible: true,
      originUrl: ''
    }
  },
  computed: {
    communityVisible () {
      return this.$route.name === 'customStatistics3'
    },
    regionVisible () {
      return this.$route.name === 'customStatistics2'
    },
    userType () {
      return this.$store.state.userInfo.userType
    },
    userInfo () {
      return this.$store.state.userInfo
    }
  },
  mounted () {
    // let ftype = {
    //   '101': 3, // 项目
    //   '102': 2 // 区域
    // }
    // 平台 1
    // this.getEnterpriseURL(ftype[this.userType] || 1)
    if (this.userType === '101') {
      this.communityId = this.$store.state.userInfo.communityId
    }
    this.getEnterpriseURL(this.userType)
  },
  methods: {
    onCommunityChange (community) {
      let originUrl = this.originUrl
      if (community && community.id) {
        if (url.indexOf('?') > -1) {
          this.url = `${originUrl}&param_ftype=101&param_fvalue=${community.id}`
          this.iframeVisible = false
          this.$nextTick(() => { this.iframeVisible = true })
        }
      } else if (this.userType === '102') {
        let regionId = this.$store.state.userInfo.orgId
        this.url = `${url}param_fvaluef=${regionId}`
        this.iframeVisible = false
        this.$nextTick(() => { this.iframeVisible = true })
      } else {
        this.url = url
        this.iframeVisible = false
        this.$nextTick(() => { this.iframeVisible = true })
      }
    },
    onRegionChange (region) {
      if (this.regionId) {
        if (url.indexOf('?') > -1) {
          this.url = `${url}&param_fvalue=${this.regionId}`
          // 让iframe重新加载
          this.iframeVisible = false
          this.$nextTick(() => { this.iframeVisible = true })
        }
      } else {
        this.url = ''
      }
    },
    async getEnterpriseURL (type) {
      let communityId = this.communityId
      let { data } = await this.$axios.get(fetchChartURL, { params: { onlyCode: 'employee_health' } })
      this.originUrl = data.url
      url = `${data.url}&param_ftype=${type}`
      if (type === '101') {
        this.url = `${url}&param_fvalue=${communityId}`
      } else if (type === '102') {
        let regionId = this.$store.state.userInfo.orgId
        this.url = `${url}&param_fvalue=${regionId}`
      } else {
        this.url = url
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.CustomAnalysisList {
  display: flex;
  flex-direction: column;
  background: #fff;
  text-align: left;
  padding: 0 20px;
  .iframe-container {
    width: 100%;
    height: 100%;
    flex: 1;
  }
}
</style>
