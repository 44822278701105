import { render, staticRenderFns } from "./employeeHealth.vue?vue&type=template&id=d883a0b8&scoped=true"
import script from "./employeeHealth.vue?vue&type=script&lang=js"
export * from "./employeeHealth.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d883a0b8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\teamHonorWork\\tianheyun\\managerforshushunew\\node_modules\\_vue-hot-reload-api@2.3.4@vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d883a0b8')) {
      api.createRecord('d883a0b8', component.options)
    } else {
      api.reload('d883a0b8', component.options)
    }
    module.hot.accept("./employeeHealth.vue?vue&type=template&id=d883a0b8&scoped=true", function () {
      api.rerender('d883a0b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/data/employeeHealth/employeeHealth.vue"
export default component.exports